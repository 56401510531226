export const theme = {
  colours: {
    darkGold: '#7f531f',
    lightGold: '#d5b01e',
    black: '#000',
    white: '#fff',
  },
  fonts: {
    nav: '2.2rem',
  },
};

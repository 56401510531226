import styled from 'styled-components';

export const Button = styled.button`
  width: 50px;
  height: 50px;
  display: flex;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;

  color: white;
`;
